<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col>
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3 class="mt-1" style="display: inline-block">
              مدیریت کارمندان غیرفعال
            </h3>
          </v-col>
          <v-col class="btn-col">
            <v-btn
              style="float: left"
              @click="gotoNewPerson()"
              v-if="role != 'reception'"
              class="primary-btn ms-5 me-5"
            >
              <!--<h6 class="mt-1">{{ faJob }} جدید +</h6>-->
              <v-icon left>person_add</v-icon>
              افزودن
            </v-btn></v-col
          >
        </v-row>
      </v-card-title>
      <v-card class="pa-5 cards">
        <v-card-text class="pa-0">
          <div class="mb-1 pa-2 pb-5">
            <v-btn
              @click="
                roleFilter = 'all';
                CurrentPage = 1;
              "
              class="secondary-btn pa-5 me-2 mt-2"
              :class="roleFilter == 'all' ? 'primary-btn' : 'secondary-btn'"
            >
              <span>همه ({{ Items.length }})</span>
            </v-btn>
            <v-btn
              @click="
                roleFilter = item;
                CurrentPage = 1;
              "
              class="secondary-btn pa-5 me-2 mt-2"
              v-for="(item, index) in employeeJobs.filter((x) => x != '-')"
              :key="index"
              :class="roleFilter == item ? 'primary-btn' : 'secondary-btn'"
              :disabled="!Items.filter((x) => x.role == item).length"
            >
              <span
                >{{
                  item + " (" + Items.filter((x) => x.role == item).length + ")"
                }}
              </span>
            </v-btn>
          </div>
          <!--<v-container class="">-->
          <v-text-field
            v-model="Filter"
            prepend-inner-icon="mdi-magnify"
            label="جستجو"
            hide-details
            single-line
            class="text-right w-80 mb-2 search-input dr-search"
            filled
            rounded
            clearable
          ></v-text-field>
          <b-table
            responsive
            show-empty
            :fields="Fields"
            :items="chosenEmployees"
            empty-text="کارمندی برای نمایش وجود ندارد"
            empty-filtered-text="کارمندی برای نمایش وجود ندارد"
            striped
            :busy="Busy"
            :filter="Filter"
            :current-page="CurrentPage"
            :per-page="PerPage"
            @filtered="onFiltered"
            class="pa-2"
          >
            <template v-slot:head()="data">
              <div
                class="table-header"
                style="text-align: center; vertical-align: middle"
              >
                {{ data.label }}
              </div>
            </template>
            <template v-slot:cell()="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(name)="data">
              <div style="text-align: center; vertical-align: middle">
                <span
                  class="primary--text"
                  style="cursor: pointer"
                  :class="data.value != '-' ? 'table-link' : ''"
                  @click="editPerson(data.item.roleEn, data.item.id)"
                  >{{ data.value }}</span
                >
              </div>
            </template>
            <template v-slot:cell(index)="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
              </div>
            </template>
            <template v-slot:cell(operation)="data">
              <div style="text-align: center; vertical-align: middle">
                <v-btn
                  class="primary-btn pa-2"
                  @click="editPerson(data.item.roleEn, data.item.id)"
                  ><v-icon> edit</v-icon></v-btn
                >
              </div>
            </template>
            <div slot="table-busy" class="text-center primary--text my-2">
              <v-progress-circular
                indeterminate
                color="primary"
                class="align-middle"
              ></v-progress-circular>
            </div>
          </b-table>
          <v-pagination
            v-model="CurrentPage"
            :length="
              Filter
                ? Math.ceil(filteredChosenEmployees / PerPage)
                : Math.ceil(chosenEmployees.length / PerPage)
            "
            :total-visible="5"
            prev-icon="arrow_back"
            next-icon="arrow_forward"
          ></v-pagination>
          <v-select
            label="تعداد در هر صفحه:"
            style="width: 150px"
            v-model="PerPage"
            :items="perPageOptions"
            item-text="text"
            item-value="value"
            class="me-2 ms-2"
          ></v-select>
          <!--</v-container>-->
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";

export default {
  data() {
    return {
      CurrentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      PerPage: 10,
      TotalRows: "",
      Filter: "",
      Busy: true,
      Items: [],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "role", label: "سمت " },
        { key: "operation", label: "مدیریت " },
      ],
      chosenEmployee: false,
      employeeJobs: [],
      roleFilter: "all",
      filteredChosenEmployees: 0,
      allRoles: [],
    };
  },
  methods: {
    gotoNewPerson() {
      let role = this.role == "reception" ? "reception" : "admin";
      this.$router.push("/" + role + "/newPerson");
    },
    showPeople() {
      //NOTE getting all employees info when page mount
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employees/getAllInactive",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.Items.forEach((item) => {
              this.allRoles.push(item.role);
            });
            this.allRoles.forEach((element) => {
              if (!this.employeeJobs.includes(element)) {
                this.employeeJobs.push(element);
              }
            });
            this.Busy = false;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
          this.vLoading = false;
        });
    },
    editPerson(role, id) {
      if (
        role == "manager" ||
        role == "admin" ||
        role == "reception" ||
        role == "labReception" ||
        role == "dentistryAdmin"
      ) {
        this.$router.push("/admin/editreception/" + id);
      } else if (role != "dentistryNurse") {
        this.$router.push("/admin/edit" + role + "/" + id);
      } else {
        this.$router.push("/admin/editnurse/" + id);
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering

      this.filteredChosenEmployees = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  computed: {
    chosenEmployees() {
      let filtered = [];
      if (this.roleFilter != "all") {
        filtered = this.Items.filter((x) => x.role == this.roleFilter);
      } else {
        filtered = this.Items;
      }
      return filtered;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.showPeople();
  },
};
</script>
